<template>
  <div class="all">
    <Header/>
 
    <div class="part2">
      <div class="part2-text1">
        集团产业
        <div style="display: inline-block">·</div>
        职教板块
      </div>
    </div>
    <div class="part3">
      <div>当前位置：首页 > 集团概况</div>
    </div>
    <div class="part4">
      <div class="part4-text1">职教板块</div>
      <div class="part4-div1">
        <div class="part4-div1-text1">
          党的十九大提出，“深化产教融合、协同育人、校企合作”的要求，“支持和规范社会力量兴办教育”。集团公司教育板块契合国家政策，秉承“校企合作、产教融合，依托学校、独立发展”的职业教育发展模式，积极发挥企业办学的主体作用，促进人才培养供给侧和产业需求侧结构要素全方位融合。同时，围绕市场需求、产业需求等办专业，促进人才的良性发展，提高人力资本价值；
          开展引企入教、引教入企、产教融合，深化产教融合创新，积极探索发展校企共建专业、共建二级学院等合作模式，打造产教融合发展创新生态圈。
        </div>
        <div class="part4-div1-img">
          <img src="../assets/homepage/位图28.png" alt="" />
        </div>
      </div>
    </div>
    <div class="part5">
      <div class="part5-center">
        <div class="part5-center-text1">合作模式</div>
        <div class="part5-center-text2">
          国务院下发的《关于加快发展现代职业教育的决定》中提出，引导支持社会力量兴办职业教育，创新民办职业教育办学模式，积极支持各类办学主体通过独资、合资、合作等多种形式举办民办职业教育；探索发展现代化产业学院。集团公司积极响应国家的一系列职教改革政策，深化产教融合、校企合作，参与学校办学，探索校企合作模式。企业参与学校办学注重人才的实用性与实效性，注重在校学习与企业实践。同时，注重学校与企业资源、信息共享的“双赢”模式。目前与学校合作的模式有：校企共建专业、校企共建二级学院。
        </div>
        <img src="../assets/homepage/位图29.png" alt="" />
      </div>
    </div>
    <div class="part6">
      <div class="part6-center">
        <div class="part6-center-text">特色优势</div>
        <div
          class="part6-center-div1"
          v-for="item in ObjectList"
          :key="item.img"
        >
          <img :src="item.img" class="part6-center-div1-img" />
          <div class="part6-center-div1-text1">{{ item.text1 }}</div>
          <div class="part6-center-div1-text2">{{ item.text2 }}</div>
        </div>
      </div>
    </div>
    <div class="part7">
      <div class="part7-text">教学产品</div>
      <div class="part7-div" v-for="item in imgList" :key="item.img">
        <img :src="item.img" class="part7-div-img" />
        <div class="part7-div-text1">{{ item.text1 }}</div>
        <div class="part7-div-text2">{{ item.text2 }}</div>
      </div>
    </div>
    <Nav></Nav>
  </div>
</template>
<style scoped>
@import url("../assets/style10.css");
</style>
<script>
import Nav from "../components/nav1/bottomnav.vue";
import Header from "../components/nav1/index2.vue";
export default {
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      ObjectList: [
        {
          img: require("../assets/homepage/组件1.png"),
          text1: "完善的就业服务体系",
          text2:
            "与知名企业、幼教机构保持长期的合作关系，如阿里巴巴、百度、京东、华为、汇爱教育等，助力学生高薪就业。",
        },
        {
          img: require("../assets/homepage/组件2.png"),
          text1: "产、学、研、创一体化实训平台",
          text2:
            "结合互联网和幼教专业需求，建设有“软件开发实训中心”、“智能互联网实训室”、“数字媒体技术实训室”、“人工智能实训室”、“产、学、研、转、创”实训基地等",
        },
        {
          img: require("../assets/homepage/组件3.png"),
          text1: "完善的实习实训体系",
          text2:
            "以企业实际项目、业务流程为背景，保障教学以及项目实训课程更接近实际生产业务流程，让学生更深层次的了解专业市场需求。",
        },
        {
          img: require("../assets/homepage/组件4.png"),
          text1: "产学融通的人才培养模式",
          text2:
            "取“专业化教育、个性化指导、全程化服务”的双创教育机制，“双线培养、学岗融通、产教融合、校企共育”的新型人才培养模式",
        },
        {
          img: require("../assets/homepage/组件5.png"),
          text1: "新锐的课程体系",
          text2:
            "以学生为主体、以能力训练为目标，与职业标准对接，产教融合的应用型课程内容体系",
        },
        {
          img: require("../assets/homepage/组件6.png"),
          text1: "雄厚的师资力量",
          text2:
            "以兼具高学历、实战经验丰富的“双师型”人才作为师资的主力军，以专业学科带头人为核心，以骨干教师为中坚，教学质量高、教学效果好、实战能力强",
        },
      ],
      imgList: [
        {
          img: require("../assets/homepage/组件7.png"),
          text1: "大数据技术",
          text2:
            "培养大数据分析挖掘与处理、移动开发与架构、云计算等高级专业大数据技术人才",
        },
        {
          img: require("../assets/homepage/组件8.png"),
          text1: "软件技术",
          text2:
            "培养从事网站开发员、信息化管理员、软件开发、软件维护等工作的应用型专门人才",
        },
        {
          img: require("../assets/homepage/组件9.png"),
          text1: "云计算技术应用",
          text2:
            "培养从事云计算技术支持、云计算运维工程师创新创业型技术技能拔尖人才",
        },
        {
          img: require("../assets/homepage/编组 22.png"),
          text1: "电子商务",
          text2:
            "培养具有较强的电子商务应用能力，从事现代电子商务运作与管理的高素质应用工程师型人才",
        },
        {
          img: require("../assets/homepage/编组 23.png"),
          text1: "网络营销与直播电商",
          text2: "培养“懂营销，会策划，善实操，精技术”的高端复合型技术人才",
        },
        {
          img: require("../assets/homepage/编组 26.png"),
          text1: "数字媒体技术",
          text2:
            "培养影视与动画设计制作、虚拟现实与游戏开发、人机交互系统研发等工作的交叉性复合型人才",
        },
        {
          img: require("../assets/homepage/编组 27.png"),
          text1: "人工智能技术应用",
          text2:
            "培养爬虫工程师、运维工程师、实施工程师等创新创业型技术技能拔尖人才",
        },
        {
          img: require("../assets/homepage/编组 29.png"),
          text1: "移动应用开发",
          text2:
            "培养互联网开发和企业网站开发领域的高素质劳动者和专业技术技能人才",
        },
        {
          img: require("../assets/homepage/编组 31.png"),
          text1: "婴幼儿托育服务与管理",
          text2:
            "培养较扎实的专业技能、活动设计和组织能力、人际交往能力等全方位婴幼儿领域的专业人才",
        },
        {
          img: require("../assets/homepage/椭圆形.png"),
          text1: "早期教育",
          text2:
            "培养从事儿童教育管理、服务及学前儿童教学活动等工作的教育工作者",
        },
        {
          img: require("../assets/homepage/椭圆形(1).png"),
          text1: "计算机应用技术",
          text2:
            "培养具备计算机专业知识和较强的计算机办公自动化、数据库等常用软件应用能力",
        },
        {
          img: require("../assets/homepage/椭圆形(6).png"),
          text1: "商务数据分析与应用",
          text2: "培养具有“懂业务、懂管理、懂分析、懂工具、懂设计”的复合型人才",
        },
        {
          img: require("../assets/homepage/椭圆形(3).png"),
          text1: "网络直播与运营",
          text2: "培养能够将互联网直播技术和传统媒体技能相结合的新型互联网人才",
        },
        {
          img: require("../assets/homepage/椭圆形(2).png"),
          text1: "物联网应用技术",
          text2:
            "培养有基础知识及专业技能，并具有较强综合职业能力的高素质劳动者和技能型人才",
        },
        {
          img: require("../assets/homepage/椭圆形(4).png"),
          text1: "智能互联网络技术",
          text2:
            "培养从事各类电子与信息系统、智能信息领域科学研究、开发及应用的复合型高级科技人才",
        },
        {
          img: require("../assets/homepage/椭圆形(5).png"),
          text1: "全媒体广告策划与营销",
          text2:
            "培养广告活动整体策划与营销推广综合能力，策划、营销、执行等工作的高素质技术技能人才",
        },
        {
          img: require("../assets/homepage/椭圆形(10).png"),
          text1: "融媒体技术与运营",
          text2:
            "培养能独立生产融媒体内容，提笔能写、举机能拍、对筒能讲的全媒体人才能力",
        },
        {
          img: require("../assets/homepage/椭圆形(8).png"),
          text1: "工业互联网技术",
          text2:
            "培养从事工业控制计算机选型、安装、应用开发的高级技术应用性专门人才",
        },
        {
          img: require("../assets/homepage/椭圆形(7).png"),
          text1: "医学营养",
          text2:
            "培养从事临床营养、公共营养、健康管理与营养咨询等工作的高素质实用型健康管理专门人才",
        },
        {
          img: require("../assets/homepage/椭圆形(9).png"),
          text1: "健康管理",
          text2:
            "培养从事健康信息检测、健康咨询、健康指导等工作的高素质实用型健康管理专门人才",
        },
      ],
    };
  },
};
</script>