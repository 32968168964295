<template>
  <div class="all">
    <Header></Header>
    <div class="part2">
      <div class="part2-text1">
        集团产业
        <div style="display: inline-block">.</div>
        幼教板块
      </div>
      <!-- <div class="part2-text2">欢迎加入我们</div> -->
    </div>
    <div class="part3">
      <div>当前位置：首页 > 集团概况</div>
    </div>
    <div class="part4">
      <div class="part4-text1">幼儿板块</div>
      <div class="part4-text2">
        目前，深圳市沃特沃德软件技术有限公司幼教产业覆盖有早教中心、托育中心、幼儿园、DIY手工乐园、医院及社区服务中心托幼一体化园区等。实体产业近20家，分别为：汇爱早教•郑州凯旋门中心、西克思社区幼托中心、汇爱国际早教托育中心等9家早教、托育中心；郑州经开区汇爱德幼稚园、郑州中原区汇爱幼稚园、郑州高新区汇爱（国际）幼稚园、原阳汇爱（国际）幼稚园、葡萄籽英语培训中心等6家幼儿园；郑州市人民医院医教医育托幼一体化融合服务点、新密市妇幼保健院托幼一体化园区（国家级示范中心）2家托幼一体化园区。<br />
        其中，郑州市人民医院医教医育托幼一体化融合服务点、新密市妇幼保健院托幼一体化园区（国家级示范中心）的建设是医教医育、产教融合的示范典例。在医院、社区服务中心的基础上建设托幼一体化的综合体，开展婴幼儿教育与医学、体育、文化、健康等多领域结合的实践和研究，探索建立医教、医育结合机制，实现医院、健康、幼儿园、托育的有效链接和融合。早教、幼儿园、托育、医院和社区服务中心托幼一体化园区等相关产业的建设将打造一个医教医育融合，产、学、研一体化协同发展的产业链。
      </div>
      <div class="part4-div2">
        <img src="../assets/homepage/位图(16).png" class="part4-div2-img1" />
        <img src="../assets/homepage/位图(17).png" class="part4-div2-img2" />
      </div>
    </div>
    <div class="part5">
      <div class="part5-center">
        <div class="part5-center-text">合作模式</div>
        <div class="part5-center-div1">
          <div class="part5-center-div1-div1">
            <div class="title">共建专业</div>
            <div class="text">
              共建婴幼儿托育服务与管理类专业，旨在坚持立德树人，面向城乡各级各类早教中心、幼儿园、托育机构、家庭教育机构，培养从事幼儿园保育、0-6婴幼儿照护、医教结合、医育一体，德智体美全面发展的高素质劳动者和技能型人才
            </div>
          </div>
          <div class="part5-center-div1-div2">
            <div class="title">订单式合作</div>
            <div class="text">
              在共建专业的基础上，企业方在满足自身产业需求的情况下，为学生拓展就业渠道，开发幼儿园、早教机构、妇幼保健机构、社区服务中心等婴幼儿教育类机构作为实习实训基地，并签订协议进行定向培养
            </div>
          </div>
          <div class="part5-center-div1-div3">
            <div class="title">共同研发项目</div>
            <div class="text">
              与高校共同合作研发科研项目，如共建人才培养机制、共研活页式教材、共建精品课程、共同培养师资团队、共建岗位规范及技术标准的合作模式
            </div>
          </div>
        </div>
        <div class="part5-center-div2">
          <div class="part5-center-div1-div4">
            <div class="title">共建学院</div>
            <div class="text">
              共建婴幼儿教育类二级学院，校企合作，以学院的形式独立运营，双方共同实施教学与运营，可以发挥学校、企业的双重优势
            </div>
          </div>
          <div class="part5-center-div1-div5">
            <div class="title">共建附属幼教机构</div>
            <div class="text">
              托幼一体化、医教结合、医养一体的婴幼儿教育是目前婴幼儿教育的发展方向，也必将助推具有先进发展理念、婴幼儿教育观念的幼教机构的建设与发展
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="part6">
      <div class="part6-text">优势特色</div>
      <div class="part6-div2">
        <img src="../assets/homepage/位图(18).png" class="part6-div2-img" />
        <div class="part6-div2-div">
          <p>集团化产业运营管理</p>
          <div class="part6-div2-div-text">
            沃特沃德信息有限公司承载民族发展使命，整合优势资源，打造以金融、科技、电商、职教、幼教为支柱的多产业集群，是全行业领先的综合性集团。沃特沃德信息有限公司幼教产业覆盖有早教中心、托育中心、幼儿园、DIY手工乐园、医院及社区服务中心托幼一体化园区等，实体产业近20家。借力金融助推产业升级，用科技引领教育行业发展，通过校企合作、产教融合发展，加强幼教产业关键学科建设，在医院、社区服务中心的基础上建设托幼一体化的综合体，开展婴幼儿教育与医学、体育、文化、健康等多领域结合的实践和研究，探索建立医教、医育结合机制，实现医院、健康、幼儿园、托育的有效链接和融合。通过早教、幼儿园、托育、医院和社区服务中心托幼一体化园区等相关产业的建设，打造一个医教医育融合，产、学、研一体化协同发展的产业链。
          </div>
        </div>
      </div>
    </div>
    <div class="part7">
      <div class="part7-text">创新人才培养</div>
      <div class="part7-div2">
        <div class="part7-div2-div1" v-for="item in ObjectList" :key="item.img">
          <img :src="item.img" />
          <div class="part7-div2-div1-text1">{{ item.text1 }}</div>
          <div class="part7-div2-div1-text2">
            {{ item.text2 }}
          </div>
        </div>
      </div>
    </div>
    <div class="part8">
      <div class="part8-text">先进完备的课程体系</div>
      <div class="part8-div1">
        <img src="../assets/homepage/位图19.png" class="part8-div1-img" />
        <div class="part8-div1-div">
          <div class="part8-div1-div-text1">分龄课程体系</div>
          <div class="part8-div1-div-text2">
            融合中国传统文化精髓与西方最先进的教育理念，强调体验、互动与对话的婴幼儿教育模式，遵循从知学到好学再到乐学的婴幼儿托育规律，结合情绪调节、行为调节、注意调节三个不同发展阶段，进行相分龄课程体系的设置，让孩子在体验互动中求知、成长，如欢动游戏课程、综合认知课程、创意美术活动、绘本阅读、美厨体验、音乐聆听等内容，进行婴幼儿内心接受和身心发展相适应的教育，真正实现婴幼儿阶段教育的自然衔接和过渡，促进婴幼儿的健康成长。
          </div>
        </div>
      </div>
      <div class="part8-div2">
        <div class="part8-div1-div">
          <div class="part8-div1-div-text1">婴幼测评体系</div>
          <div class="part8-div1-div-text2">
            运用多种先进的检测设备和测试方法，搜集婴幼儿身心发展数据，帮助保健人员、教师和家长正确认识婴幼儿发展关键期，从婴幼儿身心健康、个性品质、婴幼儿营养、婴幼儿教育、环境及婴幼儿保护6个方面为婴幼儿建立综合性、发展性测评体系，实现以学定教，以婴幼儿为本的教育。通过科学测评体系掌握婴幼儿各方面能力发展水平，了解婴幼儿个体差异，从而进行有针对性地指导，实现高质量养育，促进婴幼儿全面协调发展。
          </div>
        </div>
        <img src="../assets/homepage/位图20.png" class="part8-div1-img" />
      </div>
      <div class="part8-div2">
        <img src="../assets/homepage/位图21.png" class="part8-div1-img" />
         <div class="part8-div1-div">
          <div class="part8-div1-div-text1">多元能力发展</div>
          <div class="part8-div1-div-text2">
            目前我单位实体产业正在联合郑州市人民医院共同开发录制医教结合、养育照护系列12集视频，从健康、营养、回应性照护、安全保障、早起学习等五大要素出发，以养育照护为导向，以医教融合为模式，积极探索医教融合的先进课程体系，逐步形成医院、园所、家庭三维儿童健康保健体系，以游戏和艺术活动为载体，让教育、医学指导形成合力，对0-6岁婴幼儿进行身体、心理早期发展进行评估与指导，关注婴幼儿个性品质发展，提高婴幼儿各方面的综合能力，创造婴幼儿健康成长环境，促进婴幼儿的全面发展。
          </div>
        </div>
      </div>
      <div class="part8-div2">
        <div class="part8-div1-div">
          <div class="part8-div1-div-text1">体验互动式教学</div>
          <div class="part8-div1-div-text2">
            遵循“生活即教育”的教学理念和婴幼儿身心发展规律，面向全体婴幼儿，关注个体差异，坚持以游戏为基本活动，保教结合，寓教于乐，促进婴幼儿健康成长；体验互动式教学，让婴幼儿在对大自然的感触中探知未来，提升其在日常生活中的动手能力、口语表达能力、社会能力、养成良好的生活习惯，培养婴幼儿的独立性，提升其自我服务能力，完善婴幼儿自我认知体系，从而建立婴幼儿成长的自信心。
          </div>
        </div>
        <img src="../assets/homepage/位图22.png" class="part8-div1-img" />
      </div>
    </div>
    <Nav></Nav>
  </div>
</template>
<style scoped>
@import url("../assets/style7.css");
</style>
<script>
import Nav from "../components/nav1/bottomnav.vue";
import Header from '../components/nav1/index2.vue';
export default {
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      ObjectList: [
        {
          img: require("../assets/homepage/8.png"),
          text1: "创新专业化人才培养体制",
          text2:
            "建立产学深度融合的对接机制、校企深度合作的共建机制以及“校企一体化”的教学管理运行机制，形成“开放、灵活、多样”的协同育人新机制，全面梳理健全教育教学、科研、人资、财务等各项管理制度，形成相互衔接、较为完备的制度保障体系",
        },
        {
          img: require("../assets/homepage/9.png"),
          text1: "构建产学融通的人才培养模式",
          text2:
            "围绕“产教融合、引领产业、专业对接、课程衔接”的核心，探索建立“双线培养、学岗融通、产教融合、校企共育”的新型人才培养模式，围绕学前教育、婴幼儿托育服务与管理等专业架构新生态，适应新产业、新业态、新技术发展需要,引领幼教产业的发展,体现应用型、复合型人才特质的培养特色",
        },
        {
          img: require("../assets/homepage/10.png"),
          text1: "创新教学方法，构筑优质课堂",
          text2:
            "采取“面向系统能力培养”等教育模式，导入1+X教育教学体系，打通专业能力培养与专业资格认定的障碍，打造突出婴幼儿教育、婴幼儿托育服务与管理类专业发展特色的教育教学方法",
        },
        {
          img: require("../assets/homepage/11.png"),
          text1: "建设一流学科专业集群",
          text2:
            "结合婴幼儿教育产业发展的必要性与可行性，根据医教结合、医育一体的婴幼儿教育、医学保健、健康管理等产业链、创新链的需要",
        },
        {
          img: require("../assets/homepage/12.png"),
          text1: "高水平“双师”队伍",
          text2:
            "适应未来技术人才培养特点，校企合作共建婴幼儿教育类专业拥有一支德才兼备、具备一定的婴幼儿医学、托育技能，并热心与幼儿共同成长，师德高尚、业务精良，对学前教育、婴幼儿托育服务与管理专业发展前沿有极强敏锐性和把控能力的高水平“双师”队伍。",
        },
        {
          img: require("../assets/homepage/13.png"),
          text1: "产、学、研一体化实训基地",
          text2:
            "为了提升理论与实践结合的紧密度，提高婴幼儿的实践能力，提升授课质量，已投建多个配备有先进钢琴实训室、美工实训室、幼儿园情境模拟教室、蒙台梭利教室、奥尔夫音乐教室的研学实训基地",
        },
      ],
    };
  },
};
</script>

