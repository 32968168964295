<template>
  <div class="all">
   <Header></Header>
    <div class="part2">
      <div class="part2-text1">
        集团产业
        <div style="display: inline-block">.</div>
        科技板块
      </div>
      <!-- <div class="part2-text2">欢迎加入我们</div> -->
    </div>
    <div class="part3">
      <div>当前位置：首页 > 集团概况</div>
    </div>
    <div class="part4">
      <div class="part4-text">科技板块</div>
      <img src="../assets/homepage/位图23.png" class="part4-img" />
      <div class="part4-div2">
        <div class="part4-div2-text1">
          以科技为引擎，以创新为动力，依托云计算、大数据、人工智能等前沿技术，携手华为、阿里、H3C、浪潮、锐捷等IT行业巨头，为智慧城市、智慧校园的建设和运营提供软硬件一体化解决方案，致力于成为“智慧城市、智慧校园”的践行者。
        </div>
        <div class="part4-div2-text2">
          集团公司科技板块契合国家数字化经济发展方向，积极探索，不断拓展，联合华为、阿里等国际一流信息化企业，围绕智慧教育、智慧城市等智慧化建设，提供全面的解决方案。深耕教育、政府行业等领域，专注于教育教学产品研发、智慧校园、人脸识别、软件产品研发、ICT实训室、智慧城市、智慧医疗、人才外包、云服务综合提供商等方向，致力于打造综合性智慧化服务平台，为客户提供高效、便捷、个性化的智慧环境。<br />
          紧跟国家信息化发展趋势，依托云计算、大数据、人工智能、移动互联、物联网等新型技术，结合不同的应用场景需求，为府、教育、交通、医疗、金融等行业提供个性化的解决方案，致力于将最新的产品和最先进的技术应用到各行各业的数字化转型中。携手行业合作伙伴，共同服务于各行各业的数字化发展，助力智慧建设。例如，为高校建设“环境智慧化、教学智慧化、学习智化、管理智慧化、生活智慧化“为核心的智慧校园，提供实验室建设、人才培养、课程建设、师资培训、产业学院建设等一系列端到端全方位的教学辅助服务等。
        </div>
      </div>
    </div>
    <div class="part5">
      <div class="part5-center">
        <div class="part5-center-text">教育信息化</div>
        <div class="part5-div1">
          <img src="../assets/homepage/实训室.png" class="part5-div1-img" />
          <div class="part5-div1-div">
            <div class="part5-div1-div-text1">教学实训平台解决方案</div>
            <div class="part5-div1-div-text2">
              依据院校专业方向，提供网络、安全、云计算、大数据、人工智能等全系列ICT实训综合解决方案，教学实训平台的设计全面落实“产、学、用、监、评”一体化的思想和模式，从教学、实践、使用、监控、评估等多方面注重专业人才和特色人才的培养。学生可以通过在教学平台的学习熟练掌握ICT实训综合解决方案的基础知识，并能运用掌握的知识在课程实验中进行动手实践。
            </div>
          </div>
        </div>
        <div class="part5-div2">
          <div class="part5-div1-div">
            <div class="part5-div1-div-text1">智慧教育云平台解决方案</div>
            <div class="part5-div1-div-text2">
              智慧教育云平台是为适应ICT时代的教学和学习需求，提供一个在线学习、在线实训、在线考试等综合服务的智慧教育平台。本平台聚集ICT行业在线教育、云上实训、基础教学、职业认证、就业指导等，致力于教育信息化建设，全面推动教育现代化，构建新时代教育的新生态。
            </div>
          </div>
          <img src="../assets/homepage/机房3.png" class="part5-div1-img" />
        </div>
        <div class="part5-div2">
          <img src="../assets/homepage/云科技.png" class="part5-div1-img" />
          <div class="part5-div1-div">
            <div class="part5-div1-div-text1">数据中心解决方案</div>
            <div class="part5-div1-div-text2">
              当前，人类社会正逐步迈向智能时代，新技术、新业务层出不穷，这使得企业的IT基础设施不断面临升级、扩容乃至重构的压力。同时，AI技术的广泛应用（人脸识别、机器学习等）又带来了对GPU、FPGA等异构计算资源的庞大需求，IT设备数量的快速增长则给数据中心带来了节能减排的压力。因此，建设一个高性能、智能、节能的数据中心势在必行。公司顺
              应行业发展趋势，通过从能源、硬件到软件的全方位创新，提供完整的云数据中心解决方案。
            </div>
          </div>
        </div>
        <div class="part5-div2">
          <div class="part5-div1-div">
            <div class="part5-div1-div-text1">智慧校园解决方案</div>
            <div class="part5-div3"><p>智慧班牌</p></div>
            <div class="part5-div4"><p>智慧教室</p></div>
            <div class="part5-div4"><p>校园管理平台</p></div>
            <div class="part5-div5"><p>无线校园</p></div>
            <div class="part5-div4"><p>校园安防</p></div>
          </div>
          <img src="../assets/homepage/室内6.png" class="part5-div1-img" />
        </div>
      </div>
      <div class="part6">
        <div class="part6-center">
          <div class="part6-center-text1">城市信息化</div>
          <div class="part6-center-text2">
            智慧城市是把新一代信息技术充分运用在城市中各行各业基于知识社会下一代创新（创新2.0）的城市信息化高级形态，实现信息化、工业化与城镇化深度融合，有助于缓解“大城市病”，提高城镇化质量，实现精细化和动态管理，并提升城市管理成效和改善市民生活质量。
          </div>
          <div class="part6-div2">
            <div class="part6-div2-div1">
              <img src="../assets/homepage/人脸识别.png" />
              <div class="part6-div2-div1-text1">人脸识别班牌</div>
              <div class="part6-div2-div1-text2">
                人脸识别班牌是当今校园文化建设、数字化建设的系统之一。学校为每个教室配置一个前端班牌一体机，一般安装在教室门口或教室内，用来显示班级信息、当前课程信息、班级活动信息以及学校的通知信息。
              </div>
            </div>
            <div class="part6-div2-div2">
              <img src="../assets/homepage/日 历 (1).png" />
              <div class="part6-div2-div1-text1">智慧迎新系统</div>
              <div class="part6-div2-div1-text2">
                致力于实现高校在新生报名时，方便有效的办理入学、在各地均可进行交纳学费、预选宿舍等功能。移动化服务支持学生、管理员、领导通过一部手机完成90%的工作。
              </div>
            </div>
            <div class="part6-div2-div3">
              <img src="../assets/homepage/考试.png" />
              <div class="part6-div2-div1-text1">单招考务管理系统</div>
              <div class="part6-div2-div1-text2">
                单招考务管理系统包含考生报名智能填报、考生自助服务、智慧考务管理、数据统计分析等功能。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="part7">
        <div class="part7-text1">华为云</div>
        <div class="part7-text2">
          携手华为专业、安全、可信的云计算产品，全球领先的研发创新能力，满足更广泛业务需求的行业解决方案，建设和谐共赢的云生态系统。
        </div>
        <div class="part7-div">
          <div class="part7-div1">
            <div>
              <div class="part7-div1-text1">云计算</div>
              <div class="part7-div1-div1">
                <div style="display: inline-block; vertical-align: top">
                  弹性云服务器
                </div>
                <div
                  style="
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 18px;
                  "
                >
                  云容器引
                </div>
                <div class="part7-div1-div2">
                  <div style="display: inline-block; vertical-align: top">
                    弹性伸缩服务
                  </div>
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      margin-left: 18px;
                    "
                  >
                    镜像服务
                  </div>
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      margin-left: 18px;
                    "
                  >
                    专属云
                  </div>
                </div>
              </div>
            </div>
            <div class="part7-div1-div3">
              <div class="part7-div1-text1">云储存</div>
              <div class="part7-div1-div1">
                <div style="display: inline-block; vertical-align: top">
                  云硬盘
                </div>
                <div
                  style="
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 18px;
                  "
                >
                  对象储存服务
                </div>
                <div
                  style="
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 18px;
                  "
                >
                  云硬盘备份
                </div>
                <div class="part7-div1-div2">
                  <div style="display: inline-block; vertical-align: top">
                    云服务器备份
                  </div>
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      margin-left: 18px;
                    "
                  >
                    数据快递服务
                  </div>
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      margin-left: 18px;
                    "
                  >
                    弹性文件服务
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="part7-div2">
            <img src="../assets/homepage/编组 11.png" />
          </div>
          <div class="part7-div3">
            <div>
              <div class="part7-div3-text1">云安全</div>
              <div class="part7-div3-div1">
                <div style="display: inline-block; vertical-align: top">
                  Anti-DDos流量清洗
                </div>
                <div
                  style="
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 16px;
                  "
                >
                  漏洞扫描服务
                </div>
                <div
                  style="
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 16px;
                  "
                >
                  安全指数服务
                </div>
              </div>
              <div class="part7-div3-div1">
                <div style="display: inline-block; vertical-align: top">
                  企业主机安全
                </div>
                <div
                  style="
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 16px;
                  "
                >
                  WEB应用防火墙
                </div>
                <div
                  style="
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 16px;
                  "
                >
                  数据库安全服务
                </div>
              </div>
            </div>
            <div>
              <div class="part7-div3-div2">
                <div class="part7-div3-text1">云数据库</div>
                <div class="part7-div3-div1">
                  <div style="display: inline-block; vertical-align: top">
                    云数据库MySQL
                  </div>
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      margin-left: 16px;
                    "
                  >
                    分部式缓存服务
                  </div>
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      margin-left: 16px;
                    "
                  >
                    文档数据库服务
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="part7-div4">
            <div class="part7-div4-text1">云网络</div>
            <div class="part7-div4-div">
              <div
                style="
                  display: inline-block;
                  vertical-align: top;
                  margin-left: 25px;
                "
              >
                虚拟私有云
              </div>
              <div
                style="
                  display: inline-block;
                  vertical-align: top;
                  margin-left: 16px;
                "
              >
                弹性负载均衡
              </div>
              <div
                style="
                  display: inline-block;
                  vertical-align: top;
                  margin-left: 16px;
                "
              >
                虚拟专用网络
              </div>
            </div>
          </div>
        </div>
      </div>
      <Nav></Nav>
    </div>
  </div>
</template>
<style scoped>
@import url("../assets/style8.css");
</style>
<script>
import Nav from "../components/nav1/bottomnav.vue";
import Header from '../components/nav1/index2.vue';
export default {
  components: {
    Header,
    Nav,
  }
};
</script>