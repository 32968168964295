<template>
  <div class="all">
    <div class="nav">
      <div class="nav_img">
        <img src="../../src/assets/homepage/logo.png" />
      </div>
      <div class="nav_ul">
        <ul>
          <li>首页</li>
          <li>集团概况</li>
          <li>集团产业</li>
          <li>集团新闻</li>
          <li>合作院校</li>
          <li>联系我们</li>
          <li>招聘信息</li>
        </ul>
      </div>
    </div>
    <div class="part3">
      <div>当前位置：首页 > 集团概况</div>
    </div>
    <div class="part4">
      <div class="part4-text1">
        发展数字经济 共享包容增长 为经济发展插上“数字翅膀”
      </div>
      <div class="part4-div1">
        <div>
          <img src="../assets/homepage/矩形9.png" class="part4-div1-img" />
          <div class="part4-div1-text1">发布时间：2019-11-15</div>
        </div>
        <div>
          <img src="../assets/homepage/矩形10.png" class="part4-div1-img" />
          <div class="part4-div1-text1">发布时间：2019-11-15</div>
        </div>
      </div>
      <div class="xian"></div>
      <div class="part4-div2">
        <div class="part4-div2-text1">
          “数字经济是全球未来的发展方向，创新是亚太经济腾飞的翅膀。”数字技术已成为促进经济发展、提高经济各领域竞争力、打造新兴市场并保障全面可持续增长的关键因素之一。接受本报记者采访的国际人士表示，推动数字经济进一步发展，加强数字化领域全面合作，将为促进包容性增长和可持续发展带来新的机遇。
        </div>
        <div class="part4-div2-text2">
          扩大覆盖范围，数字经济保持强劲发展势头
        </div>
        <div class="part4-div2-text1">
          11月23日发布的《中国互联网发展报告2020》指出
          ，2019年，中国数字经济规模达35.8万亿元，占国内生产总值比重达36.2%，中国数字经济总量规模和增长速度位居世界前列。近年来，中国不断扩大数字基础设施覆盖范围，培育专业化的数字人才队伍，数字经济快速发展。
        </div>
        <div class="part4-div2-text1">
          “中国拥有全球最大的互联网人口规模、最活跃的数字经济领域投资和最具活力的创业生态系统，中国将继续作为全球数字经济的领先力量。”中欧数字协会主席路易吉·甘巴尔代拉提及一系列数字：中国网民规模逾9亿；电子商务交易规模连续多年位居全球电子商务市场首位，移动支付普及率也位居世界第一；在全球知名数据智库CB
          Insights发布的全球500家独角兽企业排行榜上，中国企业有119家，排名第二。“这些数字无可争议地表明，中国数字经济保持强劲的发展势头。”
        </div>
        <div class="part4-div2-text1">
          中国不断推进数字基础设施建设给甘巴尔代拉留下深刻印象。他特别提到，在疫情防控期间，中国教育部开通国家中小学网络云平台，供全国近1.8亿中小学生免费使用，相关在线教育服务覆盖偏远和贫困地区。同时，还实施教师在线教学能力提升行动，组织多方力量制作教师在线教学能力提升培训资源包，帮助那些不习惯在线教学的教师提高数字技能。“如今，中国经济正在从高速增长阶段转向高质量发展阶段，数字经济必将在其中发挥重要引擎作用。”
        </div>
        <div class="part4-div2-text1">
          中国互联网络信息中心9月底发布的第四十六次《中国互联网络发展状况统计报告》显示，中国城乡数字鸿沟正在显著缩小。中国城乡地区互联网普及率差异为24.1%，2017年以来首次缩小到30%以内，全国贫困村通光纤比例从2017年的不足70%提升至98%。谢栋铭表示，中国政府重视在推动数字经济发展的同时关心困难群体。对世界各国的政策制定者来说，应确保民众能普遍享受到数字经济发展带来的红利。（记者
          俞懿春 方莹馨 景玥 林芮）
        </div>
      </div>
      <div class="part4-div3">
        <img src="../assets/homepage/位图30.png" alt="" />
      </div>
      <div class="part4-div4">
        <div class="part4-div4-div">
          <div style="display: inline-block; vertical-align: top">
            <img src="../assets/homepage/位图32.png" class="part4-div4-img" />

            <div class="part4-div4-text1">上一篇：业务流程管理系统</div>
          </div>
          <div style="display: inline-block; vertical-align: top">
            <div class="part4-div4-text2">下一篇：科技发展</div>
            <img src="../assets/homepage/位图31.png" class="part4-div4-img" />
          </div>
        </div>
      </div>
      <div class="part4-div5">
        <div class="part4-div5-text1">相关新闻</div>
        <div class="part4-div5-text2">更多 ></div>
      </div>
      <div class="part4-div6">
        <div class="part4-div6-div1">
          <div class="part4-div6-div1-text1">
            发展数字经济 共享包容增长 为经济发展插上“数字翅膀”
          </div>
          <div class="part4-div6-div1-text2">2021-05-30</div>
          <div class="part4-div6-div1-text3">
            中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长...
          </div>
        </div>
        <div class="part4-div6-div2">
          <div class="part4-div6-div1-text1">
            发展数字经济 共享包容增长 为经济发展插上“数字翅膀”
          </div>
          <div class="part4-div6-div1-text2">2021-05-30</div>
          <div class="part4-div6-div1-text3">
            中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长...
          </div>
        </div>
      </div>
    </div>
    <Nav></Nav>
  </div>
</template>
<style scoped>
@import url("../assets/style11.css");
</style>
<script>
import Nav from "../components/nav1/bottomnav.vue";
export default {
  components: {
    Nav,
  },
};
</script>