<template>
  <div class="all">
    <div class="all_center">
      <Header></Header>
      <div class="part2">
        <div class="part2-text1">集团新闻</div>
        <div class="part2-text2">更多最新精彩资讯，感受企业魅力</div>
      </div>
      <div class="part3">
        <div>当前位置：首页 > 新闻中心 > 企业新闻</div>
      </div>
      <div class="part4">
        <div class="part4-div1"><p>集团新闻</p></div>
        <div class="part4-div2"><p>媒体报道</p></div>
        <div class="part4-div3"><p>行业资讯</p></div>
      </div>
      <div class="part5">
        <div class="part5-img">
          <img src="../../src/assets/homepage/位图(6).png" />
        </div>
        <div class="part5-text">
          <div class="part5-text-div1">教育部等九部门关于印发《职业教育提质培优行动计划（2020—2023年）》的通知</div>
          <div class="part5-text-div2">各省、自治区、直辖市教育厅（教委）、发展改革委、工业和信息化主管部门、财政厅（局）、人力资源社会保障厅（局）、农业农村（农...</div>
          <div class="part5-text-div">
           <div class="part5-text-div-div1"> 2021/6/05</div> 
           <div class="part5-text-div-div2">查看更多></div>
            </div>
        </div>
      </div>
      <div class="part6">
        <div class="part5-img">
          <img src="../../src/assets/homepage/位图(7).png" />
        </div>
        <div class="part5-text">
          <div class="part6-text-div1">用系统思维下好“职业教育一盘大棋”</div>
          <div class="part5-text-div2">党的十九届五中全会审议通过的《中共中央关于制定国民经济和社会发展第十四个五年规划和二○三五远景目标的建议》内含了多条逻辑线...</div>
          <div class="part5-text-div">
           <div class="part6-text-div-div1"> 2021/6/05</div> 
           <div class="part5-text-div-div2">查看更多></div>
            </div>
        </div>
      </div>
      <div class="part6">
        <div class="part5-img">
          <img src="../../src/assets/homepage/位图(8).png" />
        </div>
        <div class="part5-text">
          <div class="part6-text-div1">用系统思维下好“职业教育一盘大棋”</div>
          <div class="part5-text-div2">党的十九届五中全会审议通过的《中共中央关于制定国民经济和社会发展第十四个五年规划和二○三五远景目标的建议》内含了多条逻辑线...</div>
          <div class="part5-text-div">
           <div class="part6-text-div-div1"> 2021/6/05</div> 
           <div class="part5-text-div-div2">查看更多></div>
            </div>
        </div>
      </div>
      <div class="part6">
        <div class="part5-img">
          <img src="../../src/assets/homepage/位图(9).png" />
        </div>
        <div class="part5-text">
          <div class="part6-text-div1">用系统思维下好“职业教育一盘大棋”</div>
          <div class="part5-text-div2">党的十九届五中全会审议通过的《中共中央关于制定国民经济和社会发展第十四个五年规划和二○三五远景目标的建议》内含了多条逻辑线...</div>
          <div class="part5-text-div">
           <div class="part6-text-div-div1"> 2021/6/05</div> 
           <div class="part5-text-div-div2">查看更多></div>
            </div>
        </div>
      </div>
      <div class="part6">
        <div class="part5-img">
          <img src="../../src/assets/homepage/位图(10).png" />
        </div>
        <div class="part5-text">
          <div class="part6-text-div1">用系统思维下好“职业教育一盘大棋”</div>
          <div class="part5-text-div2">党的十九届五中全会审议通过的《中共中央关于制定国民经济和社会发展第十四个五年规划和二○三五远景目标的建议》内含了多条逻辑线...</div>
          <div class="part5-text-div">
           <div class="part6-text-div-div1"> 2021/6/05</div> 
           <div class="part5-text-div-div2">查看更多></div>
            </div>
        </div>
      </div>
    <Nav></Nav>
    </div>
  </div>
</template>
<style scoped>
@import url("../assets/style3.css");
</style>
<script>
import Nav from '../components/nav1/bottomnav.vue'
import Header from '../components/nav1/index2.vue';
export default {
   components :{
     Header,
Nav
   }
}
</script>