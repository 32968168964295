<template>
  <div class="all">
    <div class="all_center">
      <Header></Header>
      <div class="part2">
        <div class="part2-text1">集团概况</div>
        <div class="part2-text2">欢迎加入我们</div>
      </div>
      <div class="part3">
        <div>当前位置：首页 > 集团概况</div>
      </div>
      <div class="part4">
        <div class="part4-div1">
          <div class="part4-div2">
            <div class="part4-div2-text1">关于企业</div>
            <div class="part4-div2-text2">
              沃特沃德软件有限公司是一家集金融、科技、电商、职教、幼教等为一体的集团化公司。在国家“科教兴国，金融助力”的大政策背景下，通过创新金融产品助力产业升级及国民经济转型，促进社会可持续发展；以科技创新推进智慧建设，以电商创新平台带动区域经济特色发展，以产业带动专业建设，深化产业与教育深度融合，以教育助力国家现代化建设。集团公司秉承脚踏实地、志存高远的企业精神，落实内涵建设，坚持守正创新，通过校企合作共建专业、共建二级学院、共建附属幼教机构等模式，致力于让人才更有价值，让科技、教育惠及大众，走出了一条特色化的发展道路。
            </div>
          </div>
          <div class="part4-div3">
            <img src="../../src/assets/homepage/蒙版(17).png" />
          </div>
          <div class="part4-div4">
            <div class="part4-div4-div1">
              <div class="part4-div4-div1-div1">
                <p class="part4-div4-div1-p1">30</p>
              </div>
              <div class="part4-div4-div1-div2">
                <span>+</span>
                <p>全国校区</p>
              </div>
            </div>
            <div class="part4-div4-div2">
              <div class="part4-div4-div1-div1">
                <p class="part4-div4-div1-p1">10</p>
              </div>
              <div class="part4-div4-div1-div2">
                <span>+</span>
                <p>覆盖城市</p>
              </div>
            </div>
            <div class="part4-div4-div2">
              <div class="part4-div4-div1-div1">
                <p class="part4-div4-div1-p1">7000</p>
              </div>
              <div class="part4-div4-div1-div2">
                <span>+</span>
                <p>优秀讲师</p>
              </div>
            </div>
            <div class="part4-div4-div2">
              <div class="part4-div4-div1-div1">
                <p class="part4-div4-div1-p1">80</p>
              </div>
              <div class="part4-div4-div1-div2">
                <span>万</span>
                <p>服务学生</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="part5">
        <div class="part5-div1">
          <div class="part5-div1-text1">企业文化</div>
          <div class="part5-div1-div">
            <div class="part5-div1-div-div1">
              <div class="part5-div1-div-div1-div1">
                <img src="../../src/assets/homepage/编组 5.png" />
              </div>
              <div class="part5-div1-div-div1-text1">企业定位</div>
              <div class="part5-div1-div-div1-text2">
                金融、科技、电商、职教、幼教协同发展，厚德思进
              </div>
            </div>
            <div class="part5-div1-div2">
              <div class="part5-div1-div-div1">
                <div class="part5-div1-div-div1-div1">
                  <img src="../../src/assets/homepage/编组 10.png" />
                </div>
                <div class="part5-div1-div-div1-text1">企业理念</div>
                <div class="part5-div1-div-div1-text2">
                  责任 &nbsp;感恩<br />
                  守正 &nbsp;创新<br />
                  融合&nbsp; 发展
                </div>
              </div>
            </div>
            <div class="part5-div1-div2">
              <div class="part5-div1-div-div1">
                <div class="part5-div1-div-div1-div1">
                  <img src="../../src/assets/homepage/编组 14.png" />
                </div>
                <div class="part5-div1-div-div1-text1">企业使命</div>
                <div class="part5-div1-div-div1-text2">
                  科技创新引领产业发展 产教融合培育技能人才
                </div>
              </div>
            </div>
            <div class="part5-div1-div2">
              <div class="part5-div1-div-div1">
                <div class="part5-div1-div-div1-div1">
                  <img src="../../src/assets/homepage/编组 15.png" />
                </div>
                <div class="part5-div1-div-div1-text1">企业愿景</div>
                <div class="part5-div1-div-div1-text2">
                  汇才聚智 <br />
                  赋能产业
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="part6">
        <div class="part6-div">
          <div class="part6-text1">总裁寄语</div>
          <div class="part6-div2">
            <div class="part6-div2-text1">
              沃特沃德信息有限公司自成立以来，始终保持战略定力，调整和优化产业结构，丰富产业布局，完成了金融、科技、电商、职教、幼教各业务板块的协同发展，以金融助力区域经济建设，以科技推进智慧建设，以产业带动专业建设，实现产教深度融合。
              面对复杂多样、瞬息万变的市场形势，集团公司将保持既定的战略方向，发扬“脚踏实地、志存高远”的企业精神，坚持“责任、感恩、守正、创新、融合、发展”的企业理念，坚守“科技创新引领产业发展，产教融合培育技能人才”的企业使命，传承精髓，创新发展。未来，前程人要勇做守正传承的孺子牛、创新发展的拓荒牛、艰苦奋斗的老黄牛；立德树人守初心，守正创新担使命；有打硬仗、闯难关的坚韧，敢担当、善作为的干劲；脚踏实地，创新不辍，实现“汇才聚智，赋能产业”的愿景，开创集团公司发展的新高度。
            </div>
            <div class="part6-div3">
              <div class="part6-div3-text">执行总裁</div>
              <div class="part6-div3-img">
                <img src="../../src/assets/homepage/杨刚.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="part7">
        <div class="part7-div">
          <div class="part7-div-text">发展历程</div>
          
          <div class="part7-div2">
            <div class="part7-div2-img1">
              <img src="../../src/assets/homepage/左翻(1).png" />
            </div>
            <div class="part7-div2-img2">
              <img src="../../src/assets/homepage/右翻(2).png" />
            </div>
            <div class="part7-div2-div1">
              <div class="dianxian">
                <div class="dian"></div>
                <div class="xian"></div>
              </div>
              <div class="text">
                <p class="part7-div2-div1-text1">2010</p>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="part8">
        <div class="part8-div">
          <div class="part8-div-text">办公环境</div>
          <div class="part8-div2">
            <div class="part8-div2-div1">
              <img src="../../src/assets/homepage/2.png" />
            </div>
            <div class="part8-div2-div2">
              <img src="../../src/assets/homepage/3.png" />
              <img
                src="../../src/assets/homepage/4.png"
                style="margin-left: 13px"
              />
              <img
                src="../../src/assets/homepage/6.png"
                style="margin-top: 9px"
              />
              <img
                src="../../src/assets/homepage/7.png"
                style="margin-left: 11px"
              />
            </div>
          </div>
        </div>
      </div>
      <Nav></Nav>
    </div>
  </div>
</template>
<style scoped>
@import url("../assets/style2.css");
</style>
<script>
import Nav from "../components/nav1/bottomnav.vue";
import Header from "../components/nav1/index2.vue";
export default {
  components: {
    Header,
    Nav,
  },
};
</script>
