<template>
  <div class="all">
    <Header/>
    <div class="part2">
      <div class="part2-text1">
        集团产业
        <div style="display: inline-block">·</div>
        电商板块
      </div>
    </div>
    <div class="part3">
      <div>当前位置：首页 > 集团概况</div>
    </div>
    <div class="about">
      <div class="part4">
        <div class="part4-text">电商板块</div>
        <div class="part4-div1">
          <div class="part4-div1-div1">
            <div class="part4-div1-div1-text">
              随着信息时代的到来和电商行业的深度调整、发展，传统行业逐渐向新兴行业转变，更多的行业参与到越来越大众化的电商行业中来。深圳市沃特沃德软件技术有限公司电商板块契合经济发展方向，以技术创新为驱动，以数字化管理为保障，汇集网店运营、跨境电商、新媒体营销、短视频直播、校园新零售等多样化的运营模式，与河南省多所院校合作，联合建立校园电商实训中心和产教融合电商实训孵化基地，打造高附加值电商新生态产业链。
            </div>
          </div>
          <div class="part4-div1-div2">
            <img src="../assets/homepage/位图24.png" />
          </div>
        </div>
      </div>
      <div class="part5">
        <img src="../assets/homepage/位图25.png" />
        <div class="part5-div1">
          <div class="part5-div1-text1">网店运营</div>
          <div class="part5-div1-text2">
            电商事业部店铺运营业务主要以淘宝、京东、拼多多店为主，经营主要产品为化妆品、生鲜等，与广州等地多个品牌厂商建立了合作关系，丰富自有品牌“知行云伊”的产品线；生鲜主要服务于地方特色农副产品的电商化，如推动中牟大蒜、玉米等在网上热销。主要店铺有：知行云护肤拼多多专营店、蜗牛拼多多专卖店、茵妆拼多多专卖店等。随着规模的不断扩大，集团公司将逐渐拓宽自有产品的类型、店铺类型，加大力度服务农副产品，提升企业价值。
          </div>
        </div>
      </div>
      <div class="part6">
        <div class="part6-div1">
          <div class="part6-div1-text1">垂直电商</div>
          <div class="part6-div1-text2">
            随着5G时代的来临，短视频、商业直播正逐渐成为电子商务和网络营销发展的核心方向，与国内知名的新媒体平台如今日头条、抖音直播、酷狗直播、淘宝直播等，建立了良好的合作关系，在图文自媒体、短视频、直播等新兴垂直电商领域联合开展培训课程。集团公司也投入大量资金配备先进的直播实训室，目前已合作的平台有：抖音直播、拼多多直播等，下一步还将开通快手直播和京喜直播等。
          </div>
        </div>
        <img src="../assets/homepage/位图26.png" class="part6-img" />
      </div>
      <div class="part6">
        <img src="../assets/homepage/位图27.png" />
        <div class="part7-div1">
          <div class="part5-div1-text1">创业孵化</div>
          <div class="part5-div1-text2">
            电商事业部与高校合作，联合建立校园电商实训中心和产教融合电商实训孵化基地，培养新型互联网应用人才。实训项目包括网店运营、网店美工、跨境电商、营销实战、微信营销、自媒体营销等，将店铺交给学生运营、直播平台由学生来操作等，每年培养的学生在2000人以上。
            践行
            “四真”，即真企业、真项目、真实习、真绩效的教学模式，引入多家企业资源，为学生提供创新创业项目。积极探索学生在产境、学境、研境、创境下的体验式学习、参与式学习、群组性学习、探究性学习等学习方式，结合真实企业项目，培养企业真实需求的人才，整合当地电商产业优质企业，引进项目，输出人才，建立产教融合生态圈。
          </div>
        </div>
      </div>
    </div>
    <Nav></Nav>
  </div>
</template> 
<style scoped>
@import url("../assets/style9.css");
</style>
<script>
import Nav from "../components/nav1/bottomnav.vue";
import Header from "../components/nav1/index2.vue";
export default {
  components: {
    Header,
    Nav,
  }
};
</script>