<template>
  <div class="nav">
    <div class="nav_img">
      <img src="../../assets/homepage/logo.png" />
    </div>
    <div class="nav_ul">
      <ul>
        <li><router-link to="/"> 首页</router-link></li>
        <li><router-link to="/about">集团概况</router-link></li>
        <li class="ddd">集团产业
           <div class="dropdown_content">
            <div class="divsss">
              <p><router-link to="/industry">金融板块</router-link></p>
              <p><router-link to="/keji">科技板块 </router-link></p>
              <p><router-link to="/dianshang">电商板块</router-link></p>
              <p><router-link to="/zhijiao">职教板块</router-link></p>
              <p><router-link to="/youjiao">幼教板块</router-link></p>
            </div>
          </div>
        </li>
        <li>
         
              <router-link to="/another">集团新闻</router-link>
           
        </li>
        <li>合作院校</li>
        <li><router-link to="/other">联系我们</router-link></li>
        <li><router-link to="/join">招聘信息</router-link></li>
      </ul>
    </div>
  </div>
</template>
<style scoped>
.ddd:hover .dropdown_content {
  display: block;
  z-index: 999;
}
.divsss p {
  text-align: center;
  line-height: 40px;
  color: #000;
}
/* .divsss {
  width: 140px;
  height: 48px;
  background: #ffffff;
 

} */
.dropdown_content {
  display: none;
  position: absolute;
  width: 140px;
  height: 306px;
  background: #ffffff;
  top: 71px;
  left: 875px;
  /* left: 192px; */
}
.nav {
  width: 1200px;
  height: 101px;
  margin: 0 auto;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.nav img {
  width: 181px;
  height: 54x;
}

.nav_ul {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23337f;
  line-height: 22px;
}

.nav_ul li {
  list-style-type: none;
}
</style>
<script>
export default {
  name: "Header",
};
</script>


