<template>
  <div class="all">
    <div class="all_center">
      <Header></Header>
      <div class="part2">
        <div class="part2-text1">联系我们</div>
        <div class="part2-text2">欢迎联系我们</div>
      </div>
      <div class="part3">
        <div>当前位置：首页 > 新闻中心 > 企业新闻</div>
      </div>
      <div class="part4">
        <div class="part4-div1">
          <div class="part4-div1-text1">联系我们</div>
          <div class="part4-div1-text2">contact us</div>
        </div>
        <div class="part4-div2">
          <div class="part4-div2-div1">
            <div class="part4-div2-div1-text1">
              <p>热线电话</p>
              <span>TEL 4000-000000</span>
            </div>
            <div class="part4-div2-div1-text2">
              <p>教务合作</p>
              <span>15888888888</span>
            </div>
          </div>
          <div class="part4-div2-div2">
            <div class="part4-div2-div1-text1">
              <p>公司邮箱</p>
              <span>logo@163.com</span>
            </div>
            <div class="part4-div2-div1-text2">
              <p>公司地址</p>
              <span>中国地区XX分区5A写字楼8-88室</span>
            </div>
          </div>
        </div>
        <div class="part4-div3">
          <div class="part4-div3-div">
            <img src="../assets/homepage/编组 1.png" />
            <span>关注微信公众号</span>
          </div>
        </div>
      </div>
      <div class="part5">
        <img src="../assets/homepage/位图(11).png">
      </div>
      <Nav></Nav>
    </div>
  </div>
</template>
<style scoped>
@import url("../assets/style4.css");
</style>
<script>
import Nav from "../components/nav1/bottomnav.vue";
import Header from '../components/nav1/index2.vue';
export default {
  components: {
    Header,
    Nav,
  },
};
</script>